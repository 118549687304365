export default {
  changePassword: {
    title: "Bitte legen Sie ein neues Passwort fest",
    enterPassword: "Neues Passwort",
    enterPasswordPlaceholder: "Neues Passwort",
    confirmPassword: "Neues Passwort wiederholen",
    confirmPasswordEmail: "Es wurde eine E-Mail zur Bestätigung der Passwortänderung an Sie versandt.",
    confirmPasswordPlaceholder: "Neues Passwort bestätigen",
    oldPassword: "Altes Passwort eingeben",
    oldPasswordPlaceholder: "Altes Passwort eingeben",
  },
  common: {
    age: "Alter",
    allOtherPlayersOfSame: "Spieler mit gleicher/gleichem",
    areYouSureYouWantToLogOut: "Möchten Sie sich wirklich abmelden?",
    apply: "Anwenden",
    average: "Mittlere",
    back: "Zurück",
    cancel: "Abbrechen",
    clear: "Zurücksetzen",
    club: "Verein",
    comparePlayers: "Spielervergleich",
    confirm: "Bestätigen",
    continue: "Weiter",
    currency: "Währung",
    email: "E-Mail",
    foot: "Fuß",
    height: "Größe",
    hide: "Ausblenden",
    language: "Sprache",
    league: "Liga",
    loading: "Loading...",
    logout: "Abmelden",
    marketValue: "Marktwert",
    marketValueForecast: "Marktwertprognose",
    max: "max.",
    maximum: "Maximal",
    minimum: "Minimum",
    min: "min.",
    month: "Monat",
    name: "Name",
    nationality: "Nationalität",
    of: "von",
    okay: "Okay",
    playerInformation: "Spielerdaten",
    position: "Position | Positionen",
    save: "Speichern",
    search: "Suchen",
    show: "Einblenden",
    validate: {
      email: "Ungültige E-Mail-Adresse",
      required: "Bitte füllen Sie dieses Feld aus",
      passwordsNotMatching: "Passwörter stimmen nicht überein",
    },
    weight: "Gewicht",
    year: "Jahr",
    yes: "Ja",
    player:"Spieler",

  },
  comparePlayers: {
    attributes: "Marktwerttreiber",
    changePlayer: "Spieler wechseln",
    contributionOnMarketValue: "Treiberwert",
    details: "Details",
    removePlayer: "Spieler entfernen",
    searchForPlayerToCompare: "Spieler zum Vergleichen suchen",
    selectPlayer: "Spieler auswählen",
    sortAttributes: "Treiber Sortieren",
    sortBy: "Von A bis Z | Von Z bis A",
    sortHighLow: "Nach Größe absteigend | Nach Größe aufsteigend",
    topFourHighlights: "Spielstatistik laufende Saison",
  },
  error: {
    actions: {
      goBack: "Zurück",
      refresh: "Refresh",
      title: "Was möchten Sie machen?",
    },
    notFound: {
      description: "Es sieht so aus, als ob die Seite, die Sie suchen, nicht verfügbar ist oder ersetzt wurde. :(",
    },
    unauthorized: {
      description: "Sie haben keine Berechtigung, diese Seite anzusehen. Bitte kontaktieren Sie den Support.",
    },
    forbidden: {
      description: "Sie haben keine Berechtigung, die angeforderte Aktion durchzuführen. Bitte kontaktieren Sie den Support.",
    },
    serverError: {
      description:
        "Entschuldigung. Es scheint ein interner Serverfehler aufgetreten zu sein. :( Versuchen Sie, die Seite zu aktualisieren oder wählen Sie unten eine Aktion aus.",
    },
    serviceUnavailable: {
      description:
        "Der Server ist derzeit nicht in der Lage, die Anfrage zu bearbeiten, da er vorübergehend überlastet ist oder Wartungsarbeiten am Server durchgeführt werden. :(",
    },
    unknownError: {
      description:
        "Es ist ein unbekannter Fehler aufgetreten. Dies tut uns leid. :( Bitte versuchen Sie, die Seite zu aktualisieren, oder kontaktieren Sie den Support.",
    },
    badRequest: {
      description: "Die Anfrage konnte vom Server aufgrund einer fehlerhaften Syntax nicht verstanden werden. :(",
    },
    invalidToken: {
      description:
        "Der Link, auf den Sie zuzugreifen versuchen, ist entweder ungültig oder ausgelaufen.:( Bitte versuchen Sie, ihn erneut zu senden oder kontaktieren Sie den Support.",
    },
  },
  login: {
    title: "Anmelden",
    emailPlaceholder: "E-Mail",
    password: "Passwort",
    passwordPlaceholder: "Bitte geben Sie Ihr Passwort ein.",
    forgotPassword: "Passwort vergessen?",
    checkEmailTitle: "E-Mail zum Zurücksetzen gesendet",
    checkEmailParagraph: "itte prüfen Sie Ihr E-Mail Postfach und klicken Sie auf 'Passwort zurücksetzen', um ein neues Passwort zu setzen.",
  },
  navigation: {
    home: "Home",
  },
  navigationUser: {
    viewProfile: "Mein Konto",
  },
  profile: {
    address: "Adresse",
    addressPlaceholder: "Adresse",
    clubName: "Vereinsname",
    clubNamePlaceholder: "Vereinsname",
    emailPlaceholder: "E-Mail",
    namePlaceholder: "Name",
    payments: "Zahlungen",
    profileUpdated: "Profil erfolgreich aktualisiert",
    resetPassword: "Passwort ändern",
    surname: "Nachname",
    surnamePlaceholder: "Nachname",
    title: "Mein Konto",
  },
  search: {
    clubs: "Verein",
    players: "Spieler",
    searchForClubs: "Suche nach Vereinen",
    searchForPlayers: "Suche nach Spielern",
    title: "Suche",
    notFound: "Leider keine Ergebnisse gefunden :(",
    filter: {
      durationOfContract: "Unter Vertrag bis",
      playerAdvisor: "Spielerberater",
    },
  },
  results: {
    contractLength: "Unter Vertrag bis",
    marketValueTrend: "Marktwert-Trend",
    marketValueOneYearTrend: "Trend 1J",
    noPlayerDataFound: "Keine Spielerdaten gefunden.",
    previousSearches: "Frühere Suchanfragen",
    searchResultsFor: "Suchergebnisse | Suchergebnisse für",
    sortBy: "Sortieren",
    totalClubMarketValue: "Gesamtmarktwert",
  },
  message: {
    warning: "Liebe Gool-User. Aufgrund von Wartungsarbeiten finden vorübergehend keine Marktwertaktualisierungen statt. Viele Grüße, Euer Gool-Team",
  },
  viewPlayer: {
    advancedBreakdown: "Detaillierte Aufschlüsselung der Martkwerttreiber",
    advisor: "Berater",
    attributeAverage: "Marktwerttreiber",
    averageContribution: "Benchmark (⌀)",
    averageValue: "Benchmark (⌀)",
    chance: "Verlust",
    chanceOfValueChanging: "Wahrscheinlichkeit eines Marktwertverlustes (>10%, >25% und >50%) innerhalb der nächsten zwei Jahre.",
    changeClub: "Verein wechseln",
    changeClubSubTitle: "Suche einen Verein, um den Einfluss des Vereins auf den Marktwert zu berechnen.",
    contractUntil: "Vertrag bis",
    contributionOfMarketValue: "Treiberwert",
    contributingValues: "Cluster Attribute",
    currentMarketValue: "Aktueller Marktwert",
    dateOfBirth: "Geburtsdatum",
    downloadPDF: "PDF herunterladen",
    downloadPlayerReport: "Download Marktwertreport",
    downloadPlayerReportPopupHeading: "Was möchten Sie machen?",
    downloadXLS: "XLS herunterladen",
    lastUpdated: "Letztes Update",
    marketValue: "Marktwert",
    marketValueAbbreviated: "Spieler",
    marketValueAverageAbbreviated: "Benchmark",
    marketValueDevelopment: "Marktwertverlauf",
    marketValueDistribution: "Marktwertverteilung",
    marketValueFullHistory: "Vollständige Historie",
    marketValueLast6Months: "Letzte 6 Monate",
    marketValuePercentTooltip: "Diese Spalte besteht aus positiven und negativen Werten, die zusammen 100% ergeben",
    marketValueSelectTimePeriod: "Zeitraum auswählen",
    matchdays: "Historischer MW",
    nationalTeam: "Nationalmannschaft",
    pastMarketValue: "Historischer MW",
    playerBreakdown: "Marktwerttreiber cluster",
    playersInBenchmark: "Spieler in Benchmark",
    playerMarketValue: "Spieler Marktwert",
    playingPosition: "Hauptposition",
    position: "Position",
    predictedMarketValue: "Prognostizierter MW",
    printOnly: {
      totalAssists: "Anzahl Assists",
      totalCleanSheet: "Anzahl Zu Null",
      totalGoals: "Anzahl Tore",
      totalGoalsConceded: "Anzahl Gegentore",
      totalMatchesPlayed: "Anzahl Spiele",
      totalMinutesPlayed: "Anzahl Spielminuten",
    },
    secondPlayingPosition: "Nebenposition",
    tooltips: {
      totalAssists: "Gesamtzahl der Assists in der aktuellen Saison",
      totalCleanSheet: "Gesamtzahl der Spiele ohne Gegentreffer in der laufenden Saison",
      totalGoals: "Gesamtzahl erzielter Tore in der laufenden Saison",
      totalGoalsConceded: "Gesamtzahl der kassierten Tore in der laufenden Saison",
      totalMatchesPlayed: "Gesamtzahl der gespielten Spiele in der laufenden Saison",
      totalMinutesPlayed: "Gesamtzahl der gespielten Minuten in der laufenden Saison",
      age: "Alter +/- 2 Jahre"
    },
  },
};
